class NetworkError extends Error {
  constructor(...params: any[]) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError)
    }
  }

  toString() {
    return 'Network error'
  }
}

export default NetworkError;