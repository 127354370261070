import { useCallback, useContext } from 'react'
import NotificationContext from '../contexts/Notification';

const useHandlePublicApiError = () => {
  const { showNotification } = useContext(NotificationContext);

  const handleApiError = useCallback((error?: Error) => {
    if (error) {
      showNotification(
        'error',
        error.toString()
      );
    }
  }, [showNotification])

  return handleApiError
}

export default useHandlePublicApiError;