class GraphApiError extends Error {
  public message: string;

  constructor(message: string) {
    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GraphApiError)
    }

    this.message = message;
  }

  toString() {
    return this.message
  }
}

export default GraphApiError;