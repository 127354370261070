import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom';
const PrivacyPolicy = loadable(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const SectionLibraryPreview = loadable(() => import('./pages/SectionLibraryPreview/SectionLibraryPreview'));
const Login = loadable(() => import('./pages/Login/Login'));

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/section-library-preview">
        <SectionLibraryPreview />
      </Route>
      <Route path="*">
        <Login />
      </Route>
    </Switch>
  );
};

export default Routes;