import { createAuthLink, AuthOptions, AUTH_TYPE } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from "@apollo/client";
import { onError } from '@apollo/client/link/error'

import AuthError from './errors/AuthError'
import GraphApiError from './errors/GraphApiError'

import appSyncConfig from "./aws-exports";

const url = `${process.env.REACT_APP_BASE_URL}/graphql`;
const region = appSyncConfig.aws_appsync_region;

const getClient = (getToken: () => Promise<string>, handleApiError: (error?: Error) => void) => {
  const auth: AuthOptions = {
    type: appSyncConfig.aws_appsync_authenticationType as AUTH_TYPE.AWS_LAMBDA,
    token: async () => {
      const token = await getToken()

      return token
    },
  };
  
  const httpLink = createHttpLink({ uri: url });
  
  const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if ((networkError as any)?.response?.status === 401) {
        handleApiError(new AuthError()) 
        return
      }

      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          handleApiError(new GraphApiError(error.message))
          break;
        }
      }
    }),
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
  ]);
  
  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}

export default getClient