import { useCallback, useContext } from 'react'
import { useAppBridge } from "@shopify/app-bridge-react";
import useShopOrigin from './useShopOrigin';
import NotificationContext from '../contexts/Notification';
import AuthError from '../errors/AuthError';
import { Redirect } from '@shopify/app-bridge/actions';

const useHandleApiError = () => {
  const app = useAppBridge();
  const shopOrigin = useShopOrigin()
  const { showNotification } = useContext(NotificationContext);

  const handleApiError = useCallback((error?: Error) => {
    if (error) {
      if (error instanceof AuthError) {
        showNotification(
          'error',
          'Your session has time out.'
        );

        Redirect.create(app).dispatch(Redirect.Action.REMOTE, `${process.env.REACT_APP_BASE_URL}/api/shopify/auth?shop=${shopOrigin}`);
      } else {
        showNotification(
          'error',
          error.toString()
        );
      }
    }
  }, [showNotification, app, shopOrigin])

  return handleApiError
}

export default useHandleApiError;