import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
const Dashboard = loadable(() => import('./pages/Dashboard/Dashboard'));
const SubscriptionConfirmation = loadable(() => import('./pages/SubscriptionConfirmation/SubscriptionConfirmation'));
const PurchaseConfirmation = loadable(() => import('./pages/PurchaseConfirmation/PurchaseConfirmation'));
const PrivacyPolicy = loadable(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const Support = loadable(() => import('./pages/Support/Support'));
const Documentation = loadable(() => import('./pages/Documentation/Documentation'));
const ThemeInstallation = loadable(() => import('./pages/ThemeInstallation/ThemeInstallation'));
const SectionLibrary = loadable(() => import('./pages/SectionLibrary/SectionLibrary'));

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/section-library">
        <SectionLibrary />
      </Route>
      <PrivateRoute path="/subscription-confirmation" exact>
        <SubscriptionConfirmation />
      </PrivateRoute>
      <PrivateRoute path="/purchase-confirmation" exact>
        <PurchaseConfirmation />
      </PrivateRoute>
      <PrivateRoute path="/theme-installation">
        <ThemeInstallation />
      </PrivateRoute>
      <PrivateRoute path="/support">
        <Support />
      </PrivateRoute>
      <PrivateRoute path="/documentation">
        <Documentation />
      </PrivateRoute>
      <PrivateRoute path="/" exact>
        <Dashboard />
      </PrivateRoute>
      <Route path="*">
        <div>Not found</div>
      </Route>
    </Switch>
  );
};

export default Routes;