import { useCallback } from 'react'
import { useAppBridge } from "@shopify/app-bridge-react";
import { getSessionToken } from "@shopify/app-bridge-utils";

const useGetToken = () => {
  const app = useAppBridge();
  const getToken = useCallback(() => {
    return getSessionToken(app)
  }, [app])

  return getToken
}

export default useGetToken;