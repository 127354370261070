import { AppProvider } from "@shopify/polaris";
import { Provider, ClientRouter } from "@shopify/app-bridge-react";
import "@shopify/polaris/dist/styles.css";
import translations from "@shopify/polaris/locales/en.json";
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import './App.css';
import Routes from './Routes';
import PublicRoutes from './PublicRoutes';
import RoutePropagator from './RoutePropagator';
import NotificationProvider from './components/providers/Notification';
import useShopOrigin from './hooks/useShopOrigin';
import useLoginUrl from './hooks/useLoginUrl';
import useHost from './hooks/useHost';
import FullPageLoading from './components/FullPageLoading';
import isInIframe from './utils/isInIframe';
import { useEffect, useMemo } from "react";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ApolloProvider } from '@apollo/client'
import getClient from './getClient'
import useGetToken from './hooks/useGetToken'
import useHandleApiError from './hooks/useHandleApiError'
import useHandlePublicApiError from './hooks/useHandlePublicApiError'

const queryClient = new QueryClient()

function MyProvider() {
  const history = useHistory();

  const getToken = useGetToken()
  const handleApiError = useHandleApiError()

  const graphqlClient = useMemo(() => {
    return getClient(getToken, handleApiError)
  }, [getToken, handleApiError])

  return (
    <ApolloProvider client={graphqlClient}>
      <ClientRouter history={history} />
      <Routes />
    </ApolloProvider>
  );
}

function EmbeddedApp() {
  const host = useHost();
  const loginUrl = useLoginUrl();

  useEffect(() => {
    if (!isInIframe()) {
      window.location.href = loginUrl 
    }
  }, [loginUrl])

  if (!isInIframe()) {
    return <FullPageLoading message="Please wait ..." />
  }

  return (
    <Provider
      config={{
        apiKey: (process.env.REACT_APP_SHOPIFY_API_KEY as string),
        host: host || 'public',
        forceRedirect: true,
      }}
    >
      <RoutePropagator />
      <MyProvider />
    </Provider>
  )
}

function StandaloneApp() {
  const handlePublicApiError = useHandlePublicApiError()

  const graphqlClient = useMemo(() => {
    return getClient(() => {
      return new Promise(resolve => resolve('public'))
    }, handlePublicApiError)
  }, [handlePublicApiError])

  return (
    <ApolloProvider client={graphqlClient}>
      <PublicRoutes />
    </ApolloProvider>
  );
}

const useNeedEmbed = () => {
  const shopOrigin = useShopOrigin();

  return useMemo(() => {
    return shopOrigin
  }, [shopOrigin])
}

function App() {
  const needEmbed = useNeedEmbed()
  console.log(needEmbed)

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppProvider i18n={translations}>
          <NotificationProvider>
            {needEmbed && (
              <EmbeddedApp />
            )}
            {!needEmbed && (
              <StandaloneApp />
            )}
          </NotificationProvider>
        </AppProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
